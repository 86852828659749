/* Slider */
.slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}

.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;

    p {
        max-width: 70%;
        margin: 0 auto;

        @include media-breakpoint-up(lg) {
            //font-size: 22px;
        }

    }
    h4 {
        color: $slider-title-color;
    }
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}




// Custom Slick Styles

// Arrows
.slick-arrow {
    //background: #fff;
    width: 50px;
    height: 50px;
    border-radius: 0%;
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    // Positions the arrow center inside the cirlce:
    svg {
        width: 25px;
        height: 25px;
        display: none;
    }
    &.prev {
        left: 10px;
        padding-right: 4px;

        background: url('../../../assets/prod/images/icon-previous.png') no-repeat center bottom transparent;
        background-size: cover;

    }
    &.next {
        right: 10px;
        padding-left: 4px;

        background: url('../../../assets/prod/images/icon-next.png') no-repeat center bottom transparent;
        background-size: cover;

    }
    &.prev-t {
        left: 10px;
        padding-right: 4px;

        background: url('../../../assets/prod/images/icon-previous.png') no-repeat center bottom transparent;
        background-size: cover;

    }
    &.next-t {
        right: 10px;
        padding-left: 4px;

        background: url('../../../assets/prod/images/icon-next.png') no-repeat center bottom transparent;
        background-size: cover;

    }

    &.prev-a {
        left: 10px;
        padding-right: 4px;

        background: url('../../../assets/prod/images/icon-previous.png') no-repeat center bottom transparent;
        background-size: cover;

    }
    &.next-a {
        right: 10px;
        padding-left: 4px;

        background: url('../../../assets/prod/images/icon-next.png') no-repeat center bottom transparent;
        background-size: cover;

    }
    @include media-breakpoint-up(xl) {
        &.prev {
            left: -60px;
        }
        &.next {
            right: -60px;
        }
    }
}


// Dots
.slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;

    li {
        margin: 0 0.25rem;
    }

    button {
        display: block;
        width: 1rem;
        height: 1rem;
        padding: 0;
        border: none;
        border-radius: 100%;
        background-color: $slick-dot-color !important;
        text-indent: -9999px;
    }

    li.slick-active button {
        background-color: $slick-active-dot-color !important;
    }

}