
// --------------------
// GLOBAL COMPONENTS
//---------------------

// Generic Section Padding - adds padding to each section - most of your sections should use this - override for special cases
section {
    padding: 60px 0px;
    position: relative;
    @include media-breakpoint-up(md) {
        padding: 80px 0px;
    }
    @include media-breakpoint-up(lg) {
        padding: 120px 0;
    
    }
}

// Background Images - positions the background image behind the content in the section
.background-image-container {
    overflow: hidden;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

	img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
    }
    
    // Background Overlay - Adds a colour overlay with transparency options
    &.semi-transparent-bg {
        &-dark {
            background: #000;
            img {
                opacity: 0.6;
            }
        }
        &-light {
            background: #fff;
            img {
                opacity: 0.4;
            }
        }
    }
}

// Youtube Video Embeds
.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px; height: 0; overflow: hidden;

    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

// Custom here

.js-modal-btn {
    cursor: pointer;
    transition-duration: 0.5s;
    transition-timing-function: linear;
    &:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
        transition-timing-function: linear;
    }
}
.position-relative {
    .position-absolute {
        left: 0;
        top: 0;
        z-index: 9;
        height: 100%;
        cursor: pointer;
        width: 100% !important;
        img {
            @include media-breakpoint-down(sm) {
                max-width: 25%;
            }
        }
    }
}

.border-top-left {
    position: relative;
    padding-top: 1.2rem;
    &:after {
        content: "";
        width: 115px;
        height: 3px;
        background-color: $border-color;
        position: absolute;
        top: 0;
        left: 0;
    }
}

.border-bottom-left {
    position: relative;
    padding-bottom: 1.2rem;
    &:after {
        content: "";
        width: 115px;
        height: 3px;
        background-color: $border-color;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.border-top-center {
    position: relative;
    padding-top: 1.2rem;
    &:after {
        content: "";
        width: 115px;
        height: 3px;
        background-color: $border-color;
        position: absolute;
        top: 0;
        left: 50%;
        margin-left: -57.5px;
    }
}

.border-bottom-center {
    position: relative;
    padding-bottom: 1.2rem;
    &:after {
        content: "";
        width: 115px;
        height: 3px;
        background-color: $border-color;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -57.5px;
    }
}

.btn-section {
    .btn {
        margin-bottom: 1em;
    }
    p {
        max-width: 90%;
        margin: 0 auto;
        font-size: 11px;
        @include media-breakpoint-up(lg) {
            font-size: 13px;
        }
        @include media-breakpoint-down(md) {
            font-size: 13px;
        }
    }
}

.video-width-frame {
    position: relative;
    padding-top: 25px;
    padding-bottom: 38.5%;
    height: 0;

    iframe {
        box-sizing: border-box;
        //background: url('../../../assets/prod/images/billy-video.png') center center no-repeat;
        background-size: contain;
        padding: 3.5% 22% 4.5%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

}

ul {
    &.list-unstyled {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin-bottom: 1.2rem;
            padding-left: 60px !important;
            position: relative;

            background: url('../../../assets/prod/images/icon-check.png') no-repeat left center transparent;
            background-size: 35px;
            p {
                strong {
                    font-family: $font-family-bold;
                }
                margin-bottom: 0;
            }

            @include media-breakpoint-down(md) {
                padding-left: 40px !important;
                padding-top: 0 !important;
                background: url('../../../assets/prod/images/icon-check.png') no-repeat left center transparent;
                background-size: 25px;
            }
        }
    }
}

ul {
    &.check-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            p {
                strong {
                    font-family: $font-family-bold;
                    display: block;
                }
                margin-bottom: 0;
            }

            margin-bottom: 1.5rem;
            padding-left: 80px !important;
            position: relative;

            &:nth-child(1) {
                background: url('../../../assets/prod/images/icon-claim.png') no-repeat left 5px transparent;
                background-size: 55px;
            }
            &:nth-child(2) {
                background: url('../../../assets/prod/images/icon-claim-2.png') no-repeat left 5px transparent;
                background-size: 55px;
            }
            &:nth-child(3) {
                background: url('../../../assets/prod/images/icon-claim-3.png') no-repeat left 5px transparent;
                background-size: 55px;
            }
            &:nth-child(4) {
                background: url('../../../assets/prod/images/icon-claim-4.png') no-repeat left 5px transparent;
                background-size: 55px;
            }
            &:nth-child(5) {
                background: url('../../../assets/prod/images/icon-claim-5.png') no-repeat left 5px transparent;
                background-size: 55px;
            }



            @include media-breakpoint-down(md) {
                padding-left: 60px !important;
                &:nth-child(1) {
                    background: url('../../../assets/prod/images/icon-claim.png') no-repeat left 5px transparent;
                    background-size: 45px;
                }
                &:nth-child(2) {
                    background: url('../../../assets/prod/images/icon-claim-2.png') no-repeat left 5px transparent;
                    background-size: 45px;
                }
                &:nth-child(3) {
                    background: url('../../../assets/prod/images/icon-claim-3.png') no-repeat left 5px transparent;
                    background-size: 45px;
                }
                &:nth-child(4) {
                    background: url('../../../assets/prod/images/icon-claim-4.png') no-repeat left 5px transparent;
                    background-size: 45px;
                }
                &:nth-child(5) {
                    background: url('../../../assets/prod/images/icon-claim-5.png') no-repeat left 5px transparent;
                    background-size: 45px;
                }

            }
        }
    }
}

.slick-track {
    display: flex;
}
.slick-track .slick-slide {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
}