// --------------------
// VARIABLES
//---------------------

$headerheight   : 150px;  // Change to approximate header height - adds padding to first section
$header-starting-width: 280px;
$header-scrolled-width: 200px;

// Colors
$primary        : #bf3afc;
$secondary      : #1d9bda;
$headerbg       : #000; // Header scrolled background color.

$body-color: #5c5c5c;
$subhead-color: #ffffff;
$heading-color: #0b0c12;

$btn-color: #ffffff;
$btn-bg-color: #bf3afc;
$btn-description: #5c5c5c;

$phone-description-color: #09b4ed;
$phone-number-color: #ffffff;
$phone-icon-color: #ffffff;

$table-border-color: #ffc839;
$border-color: #ffc839;

$bg-field: #ffffff;
$input-field-color: #0b0c12;

$slider-content-color: #ffffff;
$slider-title-color: #ffffff;
$slick-active-dot-color: #09b4ed;
$slick-dot-color: #e8e8ee;

$bg-accordion-header: #ffffff;
$accordion-header-color: #0b0c12;
$accordion-header-color-active: #09b4ed;
$bg-accordion-header-active: #1d9bda;
$bg-accordion-header-signs: #e8e8ee;
$bg-accordion-header-signs-active: #09b4ed;

$bg-light-white: #eff3fa;
$bg-light-blue: #f5fafd;

$bg-light-purple: #f8f7ff;
$color-purple: #bf3afc;


$dark-gray-color: #8f8f8f;
// --------------------
// Container Options
//---------------------

// $container-max-widths: (
//   sm: 540px,
//   md: 720px,
//   lg: 960px,
//   xl: 1140px,
//   ) !default;
  
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 992px,
    xl: 1140px,
    xxl: 1500px
) !default;
