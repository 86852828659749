form {
    input[type=text],
    input[type=email],
    input[type=tel] ,
    textarea {
        padding: 20px 15px 20px 50px;
        box-shadow: 0px 0px 61.1px 3.9px rgba(141, 141, 141, 0.06);
        border: none;
        margin-bottom: 15px;
        border-radius: 0px;
        background-size: 25px !important;
        font-size: 1rem;
        line-height: 1;
        width: 100%;
        @include media-breakpoint-up(sm) {
            padding: 35px 15px 35px 70px;
        }
    }
    textarea {
        height: 210px !important;
    }

    .form-control {
        font-size: 16px;
        width: 100%;
    }
    .form-group {

        margin-bottom: 0;
        label {
            width: 100%;
            margin-bottom: 0;
        }
    }

    label {
        position: relative;
        .icon {
            position: absolute;
            top: 15px;
            left: 15px;
            z-index: 999;
            width: 25px;
            height: 25px;
            margin: auto;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center center;
            @include media-breakpoint-up(sm) {
                top: 30px;
                left: 25px;
            }
            &.icon-name {
                background-image: url('../../../assets/prod/images/icon-user.png');
            }
            &.icon-email {
                background-image: url('../../../assets/prod/images/icon-email.png');
            }
            &.icon-phone {
                background-image: url('../../../assets/prod/images/icon-phone.png');
                background-repeat: no-repeat;
                background-size: 100%;
                background-position: center center;
                width: 18px;
                height: 30px;
                position: absolute;
                left: 25px;
                bottom: 10px;
                z-index: 999;
                top: 0;
                margin: auto;
                @include media-breakpoint-down(sm) {
                    left: 15px;
                }
            }
            &.icon-message {
                background-image: url('../../../assets/prod/images/icon-message.png');
            }
        }
        &.error {
            display: block;
            margin-top: -12px;
            margin-bottom: 12px;
            color: red;
            font-size: .9rem;
        }
    }
    input {
        &:-internal-autofill-selected {
            background-color: $white !important;
            background-image: none !important;
            color: -internal-light-dark-color(black, white) !important;
        }
    }

}