// --------------------
// BUTTON STYLE
//---------------------

.btn {
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 25px 40px;
    white-space: normal;

    border-radius: 0px;
    font-family: $btn-font-family;
    background-color: $btn-bg-color;

    @media all and  (min-width: 992px) and (max-width: 1200px) {
        font-size: 0.7rem;
    }
}