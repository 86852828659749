section {
  &#hero {
    .background-image-container img {
      object-position: left center;
    }
    h1, p {
      text-shadow: 0px 5px 4.45px rgba(94, 94, 94, 0.23);
    }
    @include media-breakpoint-up(lg) {
      padding-top: 200px;
      padding-bottom: 200px;
    }
  }

  &#section-2 {
    padding: 40px 0px;
    @include media-breakpoint-up(lg) {
    }
    h3 {
      color: $heading-color;
    }
    background-color: $bg-light-purple;
    .slide {

      img {
        margin: 0 auto;
      }
    }

  }

  &#section-3 {
    .feature-image {
      padding-right: 70px;
    }
  }

  &#section-4 {
    @include media-breakpoint-up(sm) {
      padding-bottom: 300px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 400px;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 550px;
    }
    .background-image-container {
      background-color: #f8f7fd;
      img {
        object-fit: contain;
        object-position: center bottom;
        @include media-breakpoint-up(xxl) {
          object-fit: cover;
        }
      }
    }

    .slick-initialized {
      .slick-slide {
        img {
          box-shadow: 0px 20px 35px 0px rgba(150, 151, 156, 0.22);
          @include media-breakpoint-up(xxl) {
            max-width: 450px;
          }
        }
      }
    }
  }

  &#section-5 {

  }
  &#section-6 {
    background-color: $bg-light-purple;
  }

  &#section-7 {
    background-color: $bg-light-white;
    @include media-breakpoint-up(lg) {
      background: url('../../../assets/prod/images/bg-frame.jpg') center center no-repeat $bg-light-white;
      background-size: cover;

      padding-top: 300px;
      padding-bottom: 300px;
    }

  }

  &#section-8 {
    background-color: $bg-light-purple;
    strong {
      font-family: $font-family-bold;
    }

  }
  &#section-9 {
    @include media-breakpoint-down(md) {
      .col-md-12 {
        > img {
          max-width: 75%;
        }
      }
    }
  }
  &#section-10 {

  }
  &#section-11 {
    background-color: $bg-light-purple;

    .slider-controls {
      padding: 10px;
      .slide img {
        max-width: 250px;
        max-height: 200px;
        width: 100%;
        height: 100%;
      }
    }

  }
  &#section-12 {

    @include media-breakpoint-up(xl) {
      ul {
        &.check-list {
          background: url('../../../assets/prod/images/border.png') 25px 75px no-repeat transparent;
        }
      }
    }

  }
  &#section-13 {
    background-color: $bg-light-purple;

    .row {
      &:nth-child(2) {
        img {
          max-width: 75px;
        }
      }
    }

  }
  &#section-14 {
    h4 {
      color: $color-purple;
    }
    @include media-breakpoint-up(lg) {
      padding: 150px 0;
      p {
        font-size: 21px;
      }
    }

  }
  &#section-15 {
    .background-image-container {
      img {
        object-position: center 100px;
        @include media-breakpoint-up(xxl) {
          object-position: center bottom;
        }
      }
    }

    @include media-breakpoint-up(xs) {
      padding-bottom: 200px;
    }
    @include media-breakpoint-up(md) {
      padding-bottom: 300px;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 350px;
      .btn-section {
        p {
          max-width: 70%;
        }
      }
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 450px;
    }
    @include media-breakpoint-up(xxl) {
      padding-bottom: 550px;
    }
    p.under-text {
      text-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    }
  }

}
