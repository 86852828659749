// --------------------
// Include Fonts Here
// --------------------

@font-face {
    font-family: 'Orkney Regular';
    src: url('../../../assets/src/fonts/Orkney-Regular.woff2') format('woff2'),
    url('../../../assets/src/fonts/Orkney-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Orkney Bold';
    src: url('../../../assets/src/fonts/Orkney-Bold.woff2') format('woff2'),
    url('../../../assets/src/fonts/Orkney-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Orkney Light';
    src: url('../../../assets/src/fonts/Orkney-Light.woff2') format('woff2'),
    url('../../../assets/src/fonts/Orkney-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}






// --------------------
//  Include Basic Typography
// --------------------
$headings-font-family: 'Orkney Bold';
$body-font-family: 'Orkney Regular';

$btn-font-family: 'Orkney Bold';
$font-family-btn-description: 'Orkney Regular';
$font-family-field: 'Orkney Regular';

$font-family-bold: 'Orkney Bold';
$font-family-regular: 'Orkney Regular';
$font-family-medium: '';
$font-family-light: '';
$font-family-italic: '';

$font-family-header-phone-number: 'Orkney Bold';
$font-family-header-phone-description: 'Orkney Light';

$font-family-slider-text: 'Orkney Regular';
$font-family-slider-title: 'Orkney Bold';
$font-family-slider-name: 'Orkney Bold';

$font-family-accordion-text: 'Orkney Regular';
$font-family-accordion-title: 'Orkney Bold';

$font-family-sub-header: 'Orkney Regular';

:root {
    font-size: 16px;
    line-height: 1.4;
}
html, body {
    font-family: $body-font-family;
    color: $body-color;
    @include media-breakpoint-up(md) {
        font-size: 16px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }

    h1 {
        color: $heading-color;
        font-size: 1.7rem;
        line-height: 1.3;
        @include media-breakpoint-up(md) {
            font-size: 1.8rem;
        }
        @include media-breakpoint-up(lg) {
            font-size: 2rem;
        }
        @include media-breakpoint-up(xl) {
            font-size: 2.2rem;
        }
    }

    h2 {
        font-size: 2rem;
        line-height: 1.3;
        color: $heading-color;
    }
    h3 {
        font-size: 1.2rem;
        line-height: 1.3;
        color: $heading-color;
    }
    h3 {
        font-size: 1.2rem;
        line-height: 1.3;
        color: $heading-color;
    }
}

// Headline padding
h1,h2,h3,h4,h5,h6 {
    margin-bottom: 20px;
    font-family: $headings-font-family;
}

p {
    margin-bottom: 1.5rem;
}
// Hero Specific Font Sizes
.eyebrow {
    text-transform: uppercase;
    text-shadow: 0px 3px 4.45px rgba(94, 94, 94, 0.27);
    font-family: $font-family-sub-header;
    letter-spacing: 0.35px;
    @include media-breakpoint-up(lg) {
        font-size: 18px;
    }
}
.subheadline {
    
}